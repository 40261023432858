<template>
  <div>
    <div class="box">
      <AudioPlayer
        muted
        ref="Player"
        :audio-list="audioList"
        :before-play="onBeforePlay"
        :show-prev-button="false"
        :show-next-button="false"
        :isLoop="true"
        :duration="true"
        :controls="false"
        currentTime
        @playing="playing"
        :progressInterval="250"
        :currentPlayIndex="activeIndex - 1"
        @ended="ended"
        :isPlaying="true"
        :isDragging="false"
        :show-play-button="false"
      />
    </div>
    <div class="grup-btn">
      <!-- <i class="icon iconfont icon-zuoshuangjiantou" v-tap="playPrev"></i> -->
      <i
        :class="
          play
            ? 'icon iconfont icon-bofang1 size'
            : 'icon iconfont icon-icon_video size'
        "
        @click="plays"
      ></i>
      <div style="height: 50px"></div>
      <!-- <i class="icon iconfont icon-youshuangjiantou" v-tap="playNext"></i> -->
    </div>
    <div class="cameraImg" style="display: none">
      <div style="display: none">
        <canvas width="200px" height="150px" ref="canvas"></canvas>
      </div>
    </div>
    <div class="mask" v-show="flag">
      <div>
        <img class="imgs" src="../../assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">人脸识别认证</div>
          <div class="content1">
            在您的学习过程中我们将对您进行学习身份进行核验，届时会人脸识别的方式进行核验。
          </div>
          <el-checkbox style="margin-left: 30px" v-model="checked"
            >同意《职培通在线教育云平台学员核验规定》</el-checkbox
          >
          <div class="dian" @click="normal">开始认证</div>
        </div>
      </div>
    </div>
    <div class="human_mask" v-show="flags">
      <div>
        <img class="imgs" src="../../assets/icon.png" alt="" />
        <div class="popup">
          <div class="title1">人脸识别</div>
          <video class="head" ref="video"></video>
          <div class="head_1"></div>
          <div class="dian" id="snap" @click="getImg">开始核验</div>
        </div>
      </div>
    </div>
    <div class="title">音频内容列表</div>
    <div class="list" v-if="list.length > 0">
      <div
        :class="activeIndex == index + 1 ? 'active item' : 'item'"
        v-for="(item, index) in list"
        :key="index"
        @click="playTab(index, 1)"
      >
        <span
          class="wc icon iconfont icon-zhengque1 rote"
          v-if="item.is_studied == 1"
        ></span>
        {{ index + 1 }}
        <!-- .{{ item.title }} -->
      </div>
    </div>
    <div v-else>暂无课程</div>
    <!-- <recordtime type="2" record="1"> </recordtime>
    <showtime type="2"> </showtime> -->
    <writetime type="2" :tz_time="tz_time"></writetime>
    <popups @gx="gx"> </popups>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      classid: (state) => state.classid,
      details: (state) => state.details,
      det: (state) => state.det,
      human: (state) => state.human,
    }),
  },
  data() {
    return {
      audioList: [],
      open: false,
      audioList: [],
      flag: false,
      flags: false,
      checked: false,
      human_time: null,
      list: [],
      timr: null,
      play: false,
      activeIndex: 1, //当前播放集数
      tz_time: 0,
      timeString: 0,
      timeOld: 0,
      present: 1, // 1需要跳转指定位置
      practical: 0,
      practical_flag: true,
      audio_str: 0, //音频开始播放时间
    };
  },
  methods: {
    ...mapActions([
      "getAudioList",
      "getVideoAudioCallback",
      "getknowledgeList_x",
      "getinsStudyRecord_x",
      "getpictureUpload_x",
      "faceAuth",
      "screenCuttingDuration_x",
    ]),
    gx(time) {
      console.log(time, "tz_time");
      this.tz_time = time;
    },
    normal() {
      if (this.checked == false) {
        this.$message({
          message: "请勾选协议",
          type: "warning",
        });
      } else {
        this.flagss = false;
        this.openCamera();
        this.flags = true;
        return;
      }
    },
    openCamera() {
      this.$nextTick(() => {
        var video = this.$refs.video;
        this.video = video;
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia({
              video: true,
            })
            .then(function (stream) {
              MediaStreamTrack =
                typeof stream.stop === "function"
                  ? stream
                  : stream.getTracks()[1];
              video.srcObject = stream;
              video.play();
            })
            .catch(function (err) {
              // console.log(err);
            });
        } else if (navigator.getMedia) {
          navigator
            .getMedia({
              video: true,
            })
            .then(function (stream) {
              console.log(stream);
              MediaStreamTrack = stream.getTracks()[1];
              video.src = window.webkitURL.createObjectURL(stream);
              video.play();
            })
            .catch(function (err) {
              console.log(err);
            });
        }
      });
    },

    //canvas中展示截取的图像
    getImg() {
      let that = this;
      var canvas = this.$refs.canvas;
      var context = canvas.getContext("2d");
      context.drawImage(this.video, 0, 0, 200, 150);
      var image = canvas.toDataURL("image/png");
      var timestamp = Date.parse(new Date()); //获取时间戳，防止图像重名
      var img = this.dataURLtoFile(image, timestamp + ".png"); //使用png的图像进行名称拼接
      console.log(img, "this.imageUrl");
      // this.video.srcObject.getTracks()[0].stop();
      // return;
      var imgBase64;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = function () {
        imgBase64 = this.result; //获取到base64格式图片
        console.log("imgBase64", imgBase64);
        that
          .getpictureUpload_x({
            img: imgBase64,
          })
          .then((res) => {
            if (res.successCode == 200) {
              that
                .faceAuth({
                  username: that.userInfo.username, //用户名
                  image: res.responseBody, //人脸图片
                })
                .then((ress) => {
                  if (ress.successCode == 200) {
                    that.$store.commit("STE_HUMAN", 0);
                    that.video.srcObject.getTracks()[0].stop();
                    that.flags = false;
                  } else {
                    that.video.srcObject.getTracks()[0].stop();
                    that.$router.push("/Layout/mynterpretation");
                  }
                });
            }
          });
      };
    },

    //图片转base64位
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var file = new File([u8arr], filename, { type: mime });
      return new File([u8arr], filename, { type: mime });
    },
    onBeforePlay(next) {
      console.log("这里可以做一些事情...");
      next(); // 开始播放
    },
    ended() {
      if (this.activeIndex <= this.audioList.length) {
        this.list[this.activeIndex - 1].is_finished = 1;
        if (this.det.study_config == 1) {
          this.screenCuttingDuration_x({
            class_id: this.classid, //班级id
            user_id: this.userInfo.id, //学员id
            video_time: this.practical.toFixed(0), //视频观看时长
            source: "2", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
            video_id: this.list[this.activeIndex - 1].id, //视频id
            is_finished: "1", //是否学完 1-完成 2-未完成
            last_viewing_time: this.timeOld.toFixed(0),
            sub_video_time: {
              video_time: this.inittime(
                this.list[this.activeIndex - 1].audio_time
              ), //视频时长
              video_close_time: this.timeOld.toFixed(0),
              video_start_time: this.audio_str.toFixed(0),
            }, //副表参数
          }).then((res) => {
            this.practical = 0;
          });
        } else {
          this.play = true;

          this.screenCuttingDuration_x({
            class_id: this.classid, //班级id
            user_id: this.userInfo.id, //学员id
            video_time: this.practical.toFixed(0), //视频观看时长
            source: "2", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
            video_id: this.list[this.activeIndex - 1].id, //视频id
            is_finished: "1", //是否学完 1-完成 2-未完成
            last_viewing_time: this.timeOld.toFixed(0),
            sub_video_time: {
              video_time: this.inittime(
                this.list[this.activeIndex - 1].audio_time
              ), //视频时长
              video_close_time: this.timeOld.toFixed(0),
              video_start_time: this.audio_str.toFixed(0),
            }, //副表参数
          });
          this.activeIndex += 1;
          this.$refs.Player.playNext();
          this.getLog();
        }
      }
    },
    //选择集数
    playTab(index, type) {
      if (this.studyType == 2 && index > 0) {
        this.$message({
          message: "请购买课程",
          type: "cancel",
        });
        return;
      }
      if (type == 1) {
        if (index == this.activeIndex - 1) return;
      }

      this.list[this.activeIndex - 1].viewed_time = this.timeOld;
      this.screenCuttingDuration_x({
        class_id: this.classid, //班级id
        user_id: this.userInfo.id, //学员id
        video_time: this.practical.toFixed(0), //视频观看时长
        source: "2", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
        video_id: this.list[this.activeIndex - 1].id, //视频id
        last_viewing_time: this.timeOld.toFixed(0),
        is_finished: "2", //是否学完 1-完成 2-未完成
        sub_video_time: {
          video_time: this.inittime(this.list[this.activeIndex - 1].audio_time), //视频时长
          video_close_time: this.timeOld.toFixed(0),
          video_start_time: this.audio_str.toFixed(0),
        }, //副表参数
      });

      this.activeIndex = index + 1;
      this.$refs.Player.currentPlayIndex = index;
      this.$nextTick(() => {
        this.$refs.Player.play();
        this.getLog();
      });
      this.play = true;
    },
    //播放手机
    playing() {
      var t;
      var dom = document.getElementsByClassName("audio-player__audio")[0];
      var s = dom.currentTime;
      if (s > -1) {
        var hour = Math.floor(s / 3600);
        var min = Math.floor(s / 60) % 60;
        var sec = s % 60;
        if (hour < 10) {
          t = "0" + hour + ":";
        } else {
          t = hour + ":";
        }
        if (min < 10) {
          t += "0";
        }
        t += min + ":";
        if (sec < 10) {
          t += "0";
        }
        t += sec.toFixed(0);
      }
      document.getElementsByClassName("audio__current-time")[0].innerText = t;
      if (this.practical_flag) {
        this.practical_flag = false;
        setTimeout(() => {
          this.practical += 1;
          this.practical_flag = true;
          console.log(this.practical, "========");
        }, 1000);
      }
      if (this.list[this.activeIndex - 1].is_finished == 2) {
        if (this.present == 1) {
          this.timeString = s;
          this.present = 2;
          let det = document.getElementsByClassName("audio-player__audio")[0];
          det.currentTime = this.timeOld;
          return;
        }
        if (s - this.timeString > 3) {
          if (s >= this.timeOld) {
            // console.error("huanyuan9999", this.timeOld);
            this.timeString = this.timeOld;
            let det = document.getElementsByClassName("audio-player__audio")[0];
            det.currentTime = this.timeOld;
            return;
          } else {
            this.timeString = s;
            // console.log("huanyuan", this.timeString);
            let det = document.getElementsByClassName("audio-player__audio")[0];
            det.currentTime = this.timeString;
            return;
          }
        }
        this.timeString = s;
        if (this.timeString > this.timeOld) {
          this.timeOld = s;
        }
      } else {
        this.timeString = s;
        if (this.timeString > this.timeOld) {
          this.timeOld = s;
        }
      }
    },
    //记录
    getLog() {
      setTimeout(() => {
        document.getElementsByClassName("audio__duration")[0].innerHTML =
          this.getTime(this.$refs.Player.duration);
      }, 200);

      this.getinsStudyRecord_x({
        class_id: this.classid, //班级id
        student_id: this.userInfo.id, //学员id
        courseware_id: this.list[this.activeIndex - 1].id, //音频/视频/h5 id
        type: "2", //1. 视频 2. 音频 3.h5
      });
    },
    inittime(time) {
      var s = "";
      var hour = time.split(":")[0];
      var min = time.split(":")[1];
      var sec = time.split(":")[2];
      s = Number(hour * 3600) + Number(min * 60) + Number(sec);
      return s;
    },
    plays() {
      if (!this.play) {
        this.$refs.Player.play();
        this.play = true;
        // this.getLog();
      } else {
        this.$refs.Player.pause();
        this.play = false;
      }
    },
    getTime(time) {
      var h = parseInt(time / (60 * 60));
      var m = parseInt((time - h * 60 * 60) / 60);
      var f = parseInt(time - h * 60 * 60 - m * 60);
      var date =
        (h >= 10 ? h : "0" + h) +
        ":" +
        (m >= 10 ? m : "0" + m) +
        ":" +
        (f >= 10 ? f : "0" + f);
      return date;
    },
  },

  mounted() {
    console.log(this.$refs.Player, 777777);
    this.getknowledgeList_x({
      class_id: this.classid, //班级id
      student_id: this.userInfo.id, //学员id
      type: "2", //1. 视频 2. 音频
      page: "1",
      pageSize: "1000",
    }).then((res) => {
      if ((res.successCode = 200)) {
        let data = [];
        res.responseBody.knowledge.list.forEach((item) => {
          this.audioList.push(item.audio);
          data.push({
            chapter_title: item.title,
            ...item,
          });
        });
        this.list = data;
        this.getLog();
        setTimeout(() => {
          document.getElementsByClassName("audio__duration")[0].innerHTML =
            this.getTime(this.$refs.Player.duration);
          this.playing();
          if (this.list[this.activeIndex - 1].is_finished == 2) {
            this.present = 1;
            this.timeOld = this.list[this.activeIndex - 1].viewed_time; //最长观看视频时间
            this.timeString = 0; //最长观看视频时间
          }
        }, 400);
      }
    });
    if (this.details.face == 1) {
      if (this.human == 10) {
        this.human_time = setTimeout(() => {
          that.flag = true;
        }, 30 * 60 * 1000);
      }
    }
  },
  beforeDestroy() {
    if (this.audioList.is_studied != 1) {
      //此处记录单个音频时间
      this.screenCuttingDuration_x({
        class_id: this.classid, //班级id
        user_id: this.userInfo.id, //学员id
        video_time: this.practical.toFixed(0), //视频观看时长
        source: "2", //1. 视频 2. 音频 3. h5 4. 考试 5. 录播 6. 答练
        video_id: this.list[this.activeIndex - 1].id, //视频id
        is_finished: "2", //是否学完 1-完成 2-未完成
        last_viewing_time: this.timeOld.toFixed(0),
        sub_video_time: {
          video_time: this.inittime(this.list[this.activeIndex - 1].audio_time), //视频时长
          video_close_time: this.timeOld.toFixed(0),
          video_start_time: this.audio_str.toFixed(0),
        }, //副表参数
      });
    }
    clearTimeout(this.human_time);
    this.video.srcObject.getTracks()[0].stop();
  },
  watch: {
    activeIndex(val, vals) {
      let that = this;
      this.timeOld = 0;
      this.timeString = 0;
      this.practical = 0;
      this.audio_str = 0;
      if (this.list[val - 1].is_finished == 2) {
        that.present = 1;
        that.timeOld = that.list[val - 1].viewed_time; //最长观看视频时间
        that.audio_str = that.list[val - 1].viewed_time;
        that.timeString = 0; //最长观看视频时间
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  overflow: hidden;
}
.human_mask {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  .popup {
    position: relative;
    width: 414px;
    height: 453px;
    background: #ffffff;
    box-shadow: 0px 0px 10px 0px rgba(147, 147, 147, 0.5);
    border-radius: 15px;
    .title1 {
      padding-top: 30px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #008bd5;
      line-height: 33px;
    }
    .head {
      display: block;
      margin: 0 auto;
      width: 200px;
      height: 200px;
      border-radius: 50%;
    }
    // .head_1 {
    //   position: absolute;
    //   width: 100px;
    //   height: 100px;
    //   left: 0;
    //   top: 0;
    // }
    .dian {
      border-top: 2px solid #eaeaea;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50px;
      width: 100%;
      line-height: 50px;
      margin-top: 15px;
      text-align: center;

      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      cursor: pointer;
    }
  }
}
.mask {
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  .imgs {
    display: block;
    width: 300px;
    margin: 0 auto;
    // vertical-align: middle;
  }
  .popup {
    position: relative;
    z-index: 100;
    width: 414px;
    height: 377px;
    background: #ffffff;
    border-radius: 16px;
    .title1 {
      padding-top: 30px;
      text-align: center;
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #008bd5;
      line-height: 33px;
    }
    .content1 {
      margin: 30px;
      font-size: 18px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #222222;
    }
    .dian {
      border-top: 2px solid #eaeaea;
      position: absolute;
      left: 0;
      bottom: 0;
      height: 50px;
      width: 100%;
      line-height: 50px;
      margin-top: 15px;
      text-align: center;

      font-size: 18px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #0067ff;
      cursor: pointer;
    }
  }
}
// ::v-deep .audio-player {
//   width: 100%;
// }
::v-deep .audio__progress-wrap {
  width: 100%;
}

::v-deep .audio-player .audio__progress-wrap {
  cursor: pointer;
  height: 4px !important;
}
::v-deep .audio-player .audio__play-rate {
  display: none;
}
::v-deep.audio-player .audio__play-volume-icon-wrap {
  display: none;
}
.grup-btn {
  position: relative;
  padding-top: 20px;
  .icon {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 36px;
    color: #0039ff;
    cursor: pointer;
    margin: 0 10px;
    vertical-align: middle;
  }
  .size {
    font-size: 48px;
  }
}
.title {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  border-bottom: 1px solid #e1e1e1;
  margin-bottom: 26px;
}
.list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 300px;
  .active {
    color: #137cfb !important;
    border: 2px solid #137cfb !important;
    background: #f5fbff;
  }
  .item {
    text-align: center;
    cursor: pointer;
    position: relative;
    margin-bottom: 17px;
    margin-right: 18px;
    padding: 10px;
    box-sizing: border-box;
    width: 160px;
    height: 80px;
    background: #fff;
    border-radius: 8px;
    border: 2px solid #e7e7e7;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    overflow: hidden;
    line-height: 60px;

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .wc {
    position: absolute;
    right: 0;
    top: -18px;
  }
}
</style>
